import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import '../components/all.sass'

export default function InitialConceptReviewTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  const regDoc = frontmatter.regulatoryDocumentation
  const salesvol = frontmatter.firstYearSalesVolume
  return (
    <Layout title={documentName} filePath={filePath}>
      <section className="section sectionbody">
        <p>For new product ideas</p>
        <h2 className="title is-3" id="1">
          Product Name
        </h2>
        <p>{frontmatter.productTitle}</p>
      </section>

      <section className="section sectionbody">
        <h2 className="title is-3" id="2">
          Product Description
        </h2>
        <p>{frontmatter.productDesc}</p>
      </section>

      <section className="section sectionbody">
        <h2 className="title is-3" id="3">
          Problem Solved
        </h2>
        <p>{frontmatter.problemSolved}</p>
      </section>

      <section className="section sectionbody">
        <h2 className="title is-3" id="4">
          Relevant Patents
        </h2>
        <p>{frontmatter.relevantPatents}</p>
      </section>

      <section className="section sectionbody">
        <h2 className="title is-3">Product Markets</h2>
        <p>
          This device will be sold in the following markets: USA
          {makeCheckbox(frontmatter.productMarkets._USA)}
          Canada
          {makeCheckbox(frontmatter.productMarkets._Canada)}
          EU
          {makeCheckbox(frontmatter.productMarkets._EU)}
          Other: {frontmatter.productMarkets._otherText}
          {makeCheckbox(frontmatter.productMarkets._other)}
        </p>
      </section>
      <section className="section sectionbody">
        <h2 className="title is-3">Processing</h2>
        <p>
          yes
          {makeCheckbox(frontmatter.processing._processingYes)}
          no
          {makeCheckbox(frontmatter.processing._processingNo)}
          other
          {makeCheckbox(frontmatter.processing._processingOther)}
        </p>
      </section>

      <section className="section sectionbody">
        <h2 className="title is-3">Regulatory Documentation</h2>
        <table className="table is-striped is-bordered">
          <tr>
            <th>Sterilization Validation</th>
            <th>Packaging Validation</th>
            <th>Biocompatibility</th>
          </tr>
          <tr>
            <td>
              {makeCheckbox(regDoc._sterilization._documentationSupplied)}
              Documentation Supplied
            </td>
            <td>
              {makeCheckbox(regDoc._packaging._documentationSupplied)}
              Documentation Supplied
            </td>
            <td>
              {makeCheckbox(regDoc._biocompatibility._documentationSupplied)}
              Documentation Supplied
            </td>
          </tr>
          <tr>
            <td>
              {makeCheckbox(regDoc._sterilization._toBeCompletedByTZ)}
              To Be Completed By TZ
            </td>
            <td>
              {makeCheckbox(regDoc._packaging._toBeCompletedByTZ)}
              To Be Completed By TZ
            </td>
            <td>
              {makeCheckbox(regDoc._biocompatibility._toBeCompletedByTZ)}
              To Be Completed By TZ
            </td>
          </tr>
          <tr>
            <td>
              {makeCheckbox(regDoc._sterilization._notApplicable)}
              Not Applicable
            </td>
            <td>
              {makeCheckbox(regDoc._packaging._notApplicable)}
              Not Applicable
            </td>
            <td>
              {makeCheckbox(regDoc._biocompatibility._notApplicable)}
              Not Applicable
            </td>
          </tr>
          <tr>
            <td>
              {makeCheckbox(regDoc._sterilization._doNotKnow)}
              Don't Know
            </td>
            <td>
              {makeCheckbox(regDoc._packaging._doNotKnow)}
              Don't Know
            </td>
            <td>
              {makeCheckbox(regDoc._biocompatibility._doNotKnow)}
              Don't Know
            </td>
          </tr>
        </table>
      </section>
      <section className="section sectionbody">
        <h2 className="title is-3" id="5">
          First Year Sales Volume
        </h2>
        {}
        <table className="table is-striped is-bordered">
          <tr>
            <th>US Revenue Based</th>
            <td>2020</td>
            <td>2021</td>
            <td>2022</td>
            <td>2023</td>
          </tr>
          <tr>
            <td>ASP-$200</td>
            <td>{salesvol._asp._2020}</td>
            <td>{salesvol._asp._2021}</td>
            <td>{salesvol._asp._2022}</td>
            <td>{salesvol._asp._2023}</td>
          </tr>
          <tr>
            <td>Transfer-$20</td>
            <td>{salesvol._transfer._2020}</td>
            <td>{salesvol._transfer._2021}</td>
            <td>{salesvol._transfer._2022}</td>
            <td>{salesvol._transfer._2023}</td>
          </tr>
          <tr>
            <td>Total Revenue (ASP-Transfer)</td>
            <td>{salesvol._total._2020}</td>
            <td>{salesvol._total._2021}</td>
            <td>{salesvol._total._2022}</td>
            <td>{salesvol._total._2023}</td>
          </tr>
        </table>
      </section>
      <section className="section sectionbody">
        <h2 className="title is-3" id="6">
          Approvals
        </h2>
        <table>
          <tr>
            <td>
              <u>Trent Tribou</u>_________________
            </td>
            <td>8/3/2020_____</td>
          </tr>
          <tr>
            <td>Exec Management_________________</td>
            <td>Date_____</td>
          </tr>
        </table>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQuery2($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        author
        productTitle
        productDesc
        problemSolved
        relevantPatents
        productMarkets {
          _USA
          _Canada
          _EU
          _other
          _otherText
        }
        processing {
          _processingYes
          _processingNo
          _processingOther
        }
        regulatoryDocumentation {
          _sterilization {
            _documentationSupplied
            _toBeCompletedByTZ
            _notApplicable
            _doNotKnow
          }
          _packaging {
            _documentationSupplied
            _toBeCompletedByTZ
            _notApplicable
            _doNotKnow
          }
          _biocompatibility {
            _documentationSupplied
            _toBeCompletedByTZ
            _notApplicable
            _doNotKnow
          }
        }
        firstYearSalesVolume {
          _asp {
            _2020
            _2021
            _2022
            _2023
          }
          _transfer {
            _2020
            _2021
            _2022
            _2023
          }
          _total {
            _2020
            _2021
            _2022
            _2023
          }
        }
      }
    }
  }
`

function makeCheckbox(checked) {
  if (checked === 'true') {
    return <input type="checkbox" disabled="disabled" checked />
  } else {
    return <input type="checkbox" disabled="disabled" />
  }
}
